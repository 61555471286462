<template>
  <div>
    <progress-shimmer v-if="loader" />
    <div
      v-else
      class="total-project-hour-dashboard hrinoutinfo autoDayout mxHeight"
      style="height:550px"
    >
      <div class="d-flex align-items-center justify-content-between hrFlatpicker">
        <div>
          <h3 class="mb-0 total-hours-time">
            <span class="">{{ label }}</span>
          </h3>
        </div>
      </div>
      <div
        class="all-project-progress hr-dashboard"
        style="max-height:100%"
        :class="{ 'overflow-hidden': !series || !series.length }"
      >
        <apexchart
          v-if="series && series.length"
          ref="chart"
          type="bar"
          height="450"
          :options="chartOptions"
          :series="[{ name: '', data: series }]"
          @click="handleBarClick"
          class="custom-apex-chart"

        />
        <div
          v-show="!series || !series.length"
          class="overflow-hidden"
        >
          <img
            src="@/assets/images/nodatafound/nodata.svg"
            alt="no-data"
            class="no-data-img no-data-dashboard"
          >
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import ProgressShimmer from '../../shimmer/ProgressShimmer.vue'
import { eventBus } from '../../../main'

export default {
  name: 'ViewEditHeader',
  components: {
    apexchart: VueApexCharts,
    ProgressShimmer,
  },
  props: {
    label: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: null,
    },
    colors: {
      type: Array,
      default: () => ['#000'],
    },
  },

  data() {
    return {
      loader: false,
      series: [],
      categories: [],
      project_users: [],
      project_owners: [],
      project_ids: [],
      project_slugs: [],
    }
  },

  computed: {
    chartOptions() {
      return {
        colors: this.colors,
        chart: {
          series: this.series,
          type: 'bar',
          height: 450,
          toolbar: {
            show: true,
            tools: {
              download: false,
            },
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 20,
            horizontal: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        labels: [],
        xaxis: {
          categories: this.categories,
        },
        tooltip: {
          y: {
            formatter(val) {
              return `${val}`
            },
          },
          custom: this.tooltipContent,
        },
      }
    },
  },

  mounted() {
    this.getProjectsWithOpenBugs()
  },

  methods: {
    async getProjectsWithOpenBugs() {
      this.loader = true
      this.series = []
      const input = {
        status: this.type === 'open' ? this.type : null,
      }
      const response = await this.getHTTPPostResponse('project/defect/max-defect-project-list', input, false)
      if (response && response.data) {
        this.loader = false
        const { data } = response
        this.categories = data.openDefect.map(element => element.project_name)
        this.series = data.openDefect.map(element => element.open_count)
        this.project_users = data.openDefect.map(element => element.co_ordinators)
        this.project_owners = data.openDefect.map(element => element.project_owners)
        this.project_ids = data.openDefect.map(element => element.project_id)
        this.project_slugs = data.openDefect.map(element => element.project_slug)
      }
      this.loader = false
    },

    tooltipContent({ series, dataPointIndex }) {
      const projectUsers = this.project_users[dataPointIndex] ? this.project_users[dataPointIndex] : 'No BA and Co-ordinator'
      const projectOwners = this.project_owners[dataPointIndex] ? this.project_owners[dataPointIndex] : 'No Project Manager'
      const barCount = series && series.length && series[0][dataPointIndex] ? series[0][dataPointIndex] : 0
      return `<div class="q-assure-tooltip" style="background-color:#E3E3E3">
            <span>${projectOwners}</span>
            <span>${projectUsers}</span>
            <span>${barCount}</span>
          </div>`
    },

    handleBarClick(event, chart, chartContext) {
      if (chartContext.dataPointIndex !== -1) {
        this.$store.commit('app/UPDATE_PROJECT_ID', this.project_ids[chartContext.dataPointIndex])

        this.$router.push({
          name: 'projectTask',
          params: {
            id: 'qassure',
            id2: this.project_slugs[chartContext.dataPointIndex],
            id3: this.project_ids[chartContext.dataPointIndex],
            id4: 'defects',
            page_name: 'q-dashboard',
          },
          query: {
            status: this.type == 'open' ? 'open' : 'all',
          },
        })
        eventBus.$emit('qassureTabOpen', true)
      }
    },
  },
}
</script>
<style scoped>

 
</style>
