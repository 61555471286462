<template>
  <div>
    <progress-shimmer v-show="loader" />
    <div
      v-show="!loader"
      class="total-project-hour-dashboard hrinoutinfo autoDayout mxHeight"
      style="height:550px"
    >
      <div class="d-flex align-items-center justify-content-between hrFlatpicker">
        <div>
          <h3 class="mb-0 total-hours-time">
            <span>{{ label }}</span>
            <!-- class="text-color-blue" -->
          </h3>
        </div>
      </div>
      <div
        class="all-project-progress hr-dashboard"
        style="max-height:100%"
        :class="{ 'overflow-hidden': !chartOptions.series || !chartOptions.series.length }"
      >
        <apexchart
          v-show="chartOptions.series && chartOptions.series.length"
          ref="chart"
          type="bar"
          height="450"
          :options="chartOptions"
          :series="chartOptions.series"
        />
        <div
          v-show="!chartOptions.series || !chartOptions.series.length"
          class="overflow-hidden"
        >
          <img
            src="@/assets/images/nodatafound/nodata.svg"
            alt="no-data"
            class="no-data-img no-data-dashboard"
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import * as moment from 'moment/moment'
import ProgressShimmer from '../../shimmer/ProgressShimmer.vue'

export default {
  name: 'DefectByMember',
  components: { apexchart: VueApexCharts, ProgressShimmer },
  props: {
    label: String,
    type: String,
    month: { type: String, default: '3' },
    selectUser: String,
  },

  data() {
    return {
      loader: false,
      chartSeries: [],
      categories: [],
    }
  },

  computed: {
    chartOptions() {
      return {
        series: this.chartSeries,
        colors: [
          '#006BB9', '#05A0BF', '#0AB68B', '#0B698B', '#219BE4',
          '#30A0E0', '#6E61CA', '#7BC5C1', '#9A9CEA', '#C2528B',
          '#C282FA', '#CD184F', '#E65C4F', '#EAB875', '#F1B813',
          '#F380B9', '#F82C85', '#FF6D00', '#FF8D70', '#FF9201',
        ],
        chart: {
          type: 'bar',
          height: 450,
          stacked: true,
          toolbar: { show: false, tools: { download: false } },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            dataLabels: {
              total: {
                enabled: true,
                offsetX: 0,
                style: {
                  fontSize: '13px',
                  fontWeight: 900,
                  color: '#000000',
                },
              },
              formatter: this.dataLabelsFormatter,
            },
          },
        },

        stroke: {
          width: 1,
          colors: ['#fff'],
        },
        xaxis: {
          categories: this.categories,
          labels: {
            formatter: val => val,
          },
        },
        tooltip: {
          y: {
            formatter: val => `${val}`,
          },
        },
        fill: {
          opacity: 1,
        },
        legend: {
          show: false,
        },
      }
    },
  },

  watch: { month: 'getProjectsWithOpenBugs', selectUser: 'getProjectsWithOpenBugs' },

  mounted() { this.getProjectsWithOpenBugs() },

  methods: {
    dataLabelsFormatter(val, opts) {
      const total = opts.seriesTotals.reduce((a, b) => a + b, 0)
      return total.toFixed(0)
    },
    async getProjectsWithOpenBugs() {
      this.loader = true
      const input = {
        type: this.type,
        user_id: this.selectUser || null,
        start_date: moment().endOf('month').add(1, 'd').subtract(this.month, 'months')
          .format('YYYY-MM-DD'),
        end_date: moment().endOf('month').add(1, 'd').format('YYYY-MM-DD'),
      }

      const response = await this.getHTTPPostResponse('project/defect/max-defect-create-by-member', input, false)

      if (response && response.data) {
        this.chartSeries = []
        const { data } = response

        const xAxisLabels = []
        const seriesData = []
        if (data.openDefect && data.openDefect.length) {
          data.openDefect.forEach(element => {
            xAxisLabels.push(element.name)
          })

          for (const projectName in data.openDefect[0].data) {
            const projectData = []
            for (const user of data.openDefect) {
              projectData.push(user.data[projectName] || 0)
            }
            seriesData.push({ name: projectName, data: projectData })
          }
        }
        this.categories = xAxisLabels
        this.chartSeries = seriesData

        // this.$refs.chart.updateSeries(this.chartSeries)

        this.loader = false
      } else {
        this.loader = false
      }
    },
  },
}
</script>
