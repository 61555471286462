<template>
  <div>
    <b-card
      v-if="userInfo.role !== 'HR'"
      no-body
      class="card-dashboard-section"
    >
      <b-card-header class="dashboard-header">
        <b-card-title>
          <div class="dashbord-nav">
            <feather-icon
              icon="FileTextIcon"
              size="18"
            />
            <span>QAssure Dashboard</span>
          </div>
        </b-card-title>
      </b-card-header>
    </b-card>

    <div class="mb-5">

      <b-row
        class="match-height maindashboardList"
        style="padding-bottom: 20px"
      >
        <b-col
          cols="12 mr-0"
          lg="6"
          xl="6"
          style="height: 550px;"
        >
          <top-ten-project
            label="TOP 10 PROJECTS WITH OPEN BUGS"
            type="open"
            :colors="['#006BB9']"
          />
        </b-col>
        <b-col
          cols="12 pl-0"
          lg="6"
          xl="6"
        >
          <top-ten-project
            label="TOP 10 PROJECTS WITH MAXIMUM BUGS"
            type="all"
            :colors="['#03CCCD']"
          />
        </b-col>

      </b-row>

      <b-row
        class="match-height maindashboardList"
      >
        <b-col
          cols="12"
          lg="6"
          xl="6"
          style="height: 550px;"
        >
          <avg-span-time
            label="TOP 10 PROJECTS WITH MAXIMUM AVERAGE BUG LIFE SPAN (IN HOURS)"
            type="top"
            :users="selectUser"
            :options-months="optionsMonths"
            :colors="['#0AB68B']"
          />
        </b-col>
        <b-col
          cols="12 pl-0"
          lg="6"
          xl="6"
        >
          <avg-span-time
            label="AVERAGE BUG LIFE SPAN (IN HOURS)"
            type="avg"
            :options-months="optionsMonths"
            :users="filterManagerListOptions"
            :colors="['#0B698B']"
          />
        </b-col>
      </b-row>
      <b-row class="match-height maindashboardList">
        <b-card class="w-100">
          <div class="d-flex justify-content-start align-items-center">
            <h4 class="text-color-blue fw-600 m-0">
              Filters :
            </h4>
            <custom-dropdown
              v-if="userInfo && userInfo.role === 'SA'"
              v-model="selectUser"
              :options="filterManagerListOptions"
              label="Manage By"
            />
            <custom-dropdown
              v-model="months"
              :options="optionsMonths"
              :search-box="false"
              :hide-close="true"
              label="Date/Month"
            />

          </div>
          <b-row>
            <b-col
              cols="12 p-2 mt-1"
              lg="12"
              xl="12"
              style="box-shadow: #b1acac57 0px 4px 24px 0px;border-radius: 8px;"
            >
              <defect-by-member
                label="BUGS LOGGED BY MEMBERS"
                type="bug"
                :select-user="selectUser"
                :month="months"
              />
            </b-col>
            <b-col
              cols="12 p-2 mt-1"
              lg="12"
              xl="12"
              style="box-shadow: #b1acac57 0px 4px 24px 0px;border-radius: 8px;"
            >
              <defect-by-member
                label="IMPROVEMENT LOGGED BY MEMBERS"
                type="improvement"
                :select-user="selectUser"
                :month="months"
              />
            </b-col>
          </b-row>

        </b-card>
      </b-row>
    </div>
  </div>
</template>

<script>
import TopTenProject from './topTenProject.vue'
import AvgSpanTime from './avgSpanTime.vue'
import DefectByMember from './defectByMember.vue'
import CustomDropdown from '../../../components/dropdown/customDropdown.vue'

export default {
  name: 'ViewEditHeader',
  components: {
    TopTenProject, DefectByMember, AvgSpanTime, CustomDropdown,
  },

  data() {
    return {
      loader: false,
      usersList: [],
      selectUser: null,
      months: '3',
      optionsMonths: [
        { label: '3 Months', value: '3' },
        { label: '6 Months', value: '6' },
        { label: '9 Months', value: '9' },
        { label: '12 Months', value: '12' },
      ],
    }
  },

  computed: {
    filterManagerListOptions() {
      return this.usersList
    },
  },

  mounted() {
    this.getManagerAndProjectManagementList()
  },

  methods: {
    async getManagerAndProjectManagementList() {
      const input = { status: 'open' }
      const response = await this.getHTTPPostResponse('project/defect/get-manager-and-project-management-list', input, false)
      if (response && response.data) {
        this.usersList = response.data.manageByList
      }
    },
  },
}
</script>
